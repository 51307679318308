import { acceptHMRUpdate, defineStore } from 'pinia'

import { useGrowthBook } from '~/composables/useGrowthBook'

// When curator-mapping released, use stronger type
export type Step = ReturnType<
  typeof useInfluencerOnboardingFlowStore
>['INFLUENCER_ONBOARDING_STEPS'][number]

export const useInfluencerOnboardingFlowStore = defineStore(
  'influencerOnboardingFlow',
  () => {
    const { isFeatureOnForGroup } = useGrowthBook()

    const onboardingSteps = [
      'name-country',
      'type',
      'bio',
      'website-links',
      'similar-artists',
      'genres',
      'lang-lyrics-release',
      'picture',
    ]

    const newOnboardingSteps = [
      'name-country',
      'type',
      'opportunities',
      'bio',
      'website-links',
      'similar-artists',
      'genres',
      'lang-lyrics-release',
      'picture',
    ]

    const INFLUENCER_ONBOARDING_STEPS = computed<
      typeof onboardingSteps | typeof newOnboardingSteps
    >(() =>
      isFeatureOnForGroup('curator-mapping', 'v2')
        ? newOnboardingSteps
        : onboardingSteps,
    )

    const activeStep = ref(0)
    const path = ref('/influencer/signup/v2')
    const steps = ref(INFLUENCER_ONBOARDING_STEPS.value)
    const isChangingPage = ref(false)

    const CURRENT_STEP = computed(() => {
      return steps.value[activeStep.value]
    })
    const COMPLETION_PERCENTAGE = computed<number>(() => {
      return (activeStep.value / (steps.value.length - 1)) * 100
    })

    function CHECK_ACTIVE_STEP(currentRoutePath: string) {
      steps.value.forEach((step, index) => {
        if (currentRoutePath.endsWith(`${path.value}/${step}/`))
          activeStep.value = index
      })
    }

    async function GO_TO_NEXT_STEP() {
      isChangingPage.value = true
      const fullPath = `${path.value}/${steps.value[activeStep.value + 1]}`
      if (activeStep.value < steps.value.length - 1) activeStep.value++

      // FIXME: refactor the navigation part out of the store
      const localePath = useLocalePath()
      const navigationResult = await navigateTo(localePath({ path: fullPath }))
      isChangingPage.value = false
      return navigationResult
    }

    async function GO_TO_PREVIOUS_STEP() {
      isChangingPage.value = true
      const fullPath = `${path.value}/${steps.value[activeStep.value - 1]}`
      if (activeStep.value > 0) activeStep.value--

      // FIXME: refactor the navigation part out of the store
      const localePath = useLocalePath()
      const navigationResult = await navigateTo(localePath({ path: fullPath }))
      isChangingPage.value = false
      return navigationResult
    }

    async function GO_TO_FURTHEST_STEP() {
      isChangingPage.value = true
      const { getMissingStep } = useCuratorOnboardingFlow()
      const missingStep = getMissingStep(steps.value)
      SET_ACTIVE_STEP(steps.value.indexOf(missingStep))

      // FIXME: refactor the navigation part out of the store
      const localePath = useLocalePath()
      const fullPath = `${path.value}/${missingStep}`
      const navigationResult = await navigateTo(localePath({ path: fullPath }))
      isChangingPage.value = false
      return navigationResult
    }

    function INSERT_STEP_AFTER({
      step,
      newStep,
    }: {
      step: Step
      newStep: string
    }) {
      const stepIndex = steps.value.indexOf(step)

      if (stepIndex !== -1) {
        const stepsCopy = [...steps.value]
        stepsCopy.splice(stepIndex + 1, 0, newStep)

        steps.value = stepsCopy
      }
    }

    function INSERT_STEP_BEFORE({
      step,
      newStep,
    }: {
      step: Step
      newStep: string
    }) {
      const stepIndex = steps.value.indexOf(step)
      if (stepIndex === -1) return

      const stepsCopy = [...steps.value]
      stepsCopy.splice(stepIndex, 0, newStep)

      steps.value = stepsCopy
    }

    function REMOVE_STEP(step: Step) {
      const stepIndex = steps.value.indexOf(step)
      if (stepIndex === -1) return

      const stepsCopy = [...steps.value]
      stepsCopy.splice(stepIndex, 1)

      steps.value = stepsCopy
    }
    function SET_ACTIVE_STEP(newActiveStep: number) {
      activeStep.value = newActiveStep
    }

    return {
      // state
      activeStep,
      path,
      steps,
      isChangingPage,

      // getters
      CURRENT_STEP,
      COMPLETION_PERCENTAGE,
      INFLUENCER_ONBOARDING_STEPS,

      // actions
      CHECK_ACTIVE_STEP,
      GO_TO_NEXT_STEP,
      GO_TO_PREVIOUS_STEP,
      GO_TO_FURTHEST_STEP,
      INSERT_STEP_AFTER,
      INSERT_STEP_BEFORE,
      REMOVE_STEP,
      SET_ACTIVE_STEP,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencerOnboardingFlowStore, import.meta.hot),
  )
}
